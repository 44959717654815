.contact-us-container {
  .contact-us-content {
    .contact-us-hero {
      background: url("/assets/images/contact_us/hero.jpg") no-repeat center
        center;
      background-size: cover;
      height: 800px;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      @media (max-width: 640px) {
        justify-content: center;
        flex-direction: column;
      }

      .contact-us-hero-left {
        width: 50%;
        height: 100%;
        color: #ffffff;
        font-family: acumin-pro-wide, sans-serif;
        font-weight: 200;
        font-style: normal;
        padding: 50px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: rgba(72, 136, 165, 0.8);

        @media (max-width: 1200px) {
          padding: 60px;
          width: 60%;
        }

        @media (max-width: 1000px) {
          width: 70%;
        }

        @media (max-width: 640px) {
          width: 100%;
          padding: 30px;
        }

        .contact-us-hero-left-text {
          width: 100%;
          max-width: 450px;
          font-size: 23px;
          color: #ffffff;

          @media (max-width: 1200px) {
            font-size: 28px;
          }

          @media (max-width: 1000px) {
            font-size: 24px;
          }
        }

        .contact-us-call-us {
          margin-top: 20px;

          .contact-us-phone-number {
            font-weight: 900;
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-size: 32px;
            letter-spacing: 1.5px;
          }
        }
      }

      .contact-us-hero-right {
        width: 50%;

        .contact-us-buttons {
          .contact-us-button {
            width: 600px;
            padding: 12px 0px;
            display: flex;
            flex-direction: row;
            align-items: flex-end;
            padding-left: 20px;
            color: #ffffff;
            font-size: 30px;
            cursor: pointer;
            font-family: acumin-pro-wide, sans-serif;
            font-weight: 200;
          }

          .contact-us-button-bold {
            font-family: acumin-pro-extra-condensed, sans-serif;
            font-weight: 500;
            font-size: 46px;
            line-height: 54px;
          }

          .contact-us-button-glass {
            background-color: #3182a6;

            &:hover {
              background-color: #1d6583;
            }
          }

          .contact-us-button-doors {
            margin-top: 30px;
            background-color: #6a8527;

            &:hover {
              background-color: #576e1d;
            }
          }
        }
      }
    }
  }
}
