.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  width: 100%;

  .home-hero-colored {
    position: absolute;
    top: 0;
    left: 0;
    min-height: 100%;
    height: 100%;
    width: 0%;
    // background-size: cover;
    transition: width 3s ease-in;
    z-index: 1;
  }

  .home-hero-colored-visible {
    width: 100%;
    min-height: 100%;
    height: 100%;
    background: url("/assets/images/home/home_hero_colored.jpg") no-repeat;
    background-size: cover;
    background-position: left center;
    z-index: 1;
  }

  .home-hero {
    position: relative;
    min-height: 100%;
    height: 100%;
    width: 100%;
    background: url("/assets/images/home/home_hero.jpg") no-repeat;
    background-size: cover;
    background-position: left center;
    z-index: 1;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);

    .home-hero-content {
      height: 911px;
      padding-left: 120px;
      // padding-bottom: 250px;
      padding-top: 0px;
      display: flex;
      flex-direction: row;
      align-items: center;

      @media (max-width: 975px) {
        padding-left: 60px;
      }

      .home-hero-tag {
        display: flex;
        flex-direction: row;
        z-index: 2;

        @media (max-width: 640px) {
          flex-direction: column;
        }

        img {
          @media (max-width: 1300px) {
            height: 80px;
          }

          @media (max-width: 1200px) {
            height: 70px;
          }

          @media (max-width: 1100px) {
            height: 60px;
          }

          @media (max-width: 975px) {
            height: 50px;
          }

          @media (max-width: 750px) {
            height: 40px;
          }
        }

        .home-hero-tag-meets,
        .home-hero-tag-fabrication {
          margin-left: 30px;

          @media (max-width: 640px) {
            margin-left: 0px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .home-info {
    font-family: acumin-pro-wide, sans-serif;
    font-weight: 200;
    font-style: normal;
    width: 100%;
    max-width: 1000px;
    font-size: 20px;
    padding: 100px;

    @media (max-width: 640px) {
      font-size: 20px;
      width: 100%;
      padding: 50px;
    }
  }

  .home-lower-heroes {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 500px;

    @media (max-width: 950px) {
      flex-direction: column;
      height: 1000px;
    }

    .home-glass-hero {
      width: 50%;
      background: url("/assets/images/home/glass_hero.jpg") no-repeat center
        center;
      background-size: cover;

      @media (max-width: 950px) {
        width: 100%;
        height: 500px;
      }
    }

    .home-doors-hero {
      width: 50%;
      background: url("/assets/images/home/doors_hero.jpg") no-repeat center
        center;
      background-size: cover;

      @media (max-width: 950px) {
        width: 100%;
        height: 500px;
      }
    }

    .home-lower-hero {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .home-lower-hero-text {
        text-transform: uppercase;
        color: #ffffff;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 44px;
        text-shadow: 1px 1px 8px #000000, 1px 1px 24px #000000,
          1px 1px 38px #000000;

        @media (min-width: 950px) and (max-width: 1175px) {
          font-size: 40px;
        }

        @media (min-width: 950px) and (max-width: 1050px) {
          font-size: 35px;
        }

        @media (max-width: 640px) {
          text-align: center;
          padding: 0 20px;
        }
      }

      .home-lower-hero-link {
        text-transform: uppercase;
        color: #ffffff;
        font-family: acumin-pro-extra-condensed, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 50px;
        text-shadow: 1px 1px 8px #000000, 1px 1px 24px #000000,
          1px 1px 38px #000000;
        padding: 0px 15px 5px;
        line-height: 50px;
        border: 4px solid #ffffff;

        @media (min-width: 950px) and (max-width: 1175px) {
          font-size: 45px;
          padding: 0px 12px 4px;
          border: 3px solid #ffffff;
        }

        @media (min-width: 950px) and (max-width: 1050px) {
          font-size: 40px;
          padding: 0px 10px 3px;
          border: 2px solid #ffffff;
        }
      }
    }
  }
}
