.about-us-container {
  .about-us-content-container {
    .about-us-hero {
      background: url("/assets/images/about_us/hero.jpg") no-repeat center
        center;
      background-size: cover;

      height: 800px;

      .about-us-hero-left {
        width: 50%;
        background-color: rgba(92, 94, 85, 0.8);
        color: #ffffff;
        font-family: acumin-pro-wide, sans-serif;
        font-weight: 200;
        font-style: normal;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 100px;

        @media (max-width: 1200px) {
          padding: 60px;
          width: 60%;
        }

        @media (max-width: 1000px) {
          width: 70%;
        }

        @media (max-width: 640px) {
          width: 100%;
          padding: 30px;
        }

        .about-us-hero-left-text {
          font-size: 24px;
          max-width: 600px;

          @media (max-width: 1200px) {
            font-size: 28px;
          }

          @media (max-width: 1000px) {
            font-size: 24px;
          }
        }
      }
    }

    .about-us-section {
      display: flex;
      flex-direction: row;

      .about-us-section-text {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 100px;

        @media (max-width: 640px) {
          width: 100%;
          padding: 30px;
        }

        .about-us-section-text-title {
          text-transform: uppercase;
          font-family: acumin-pro-extra-condensed, sans-serif;
          font-weight: 400;
          font-style: normal;
          font-size: 48px;

          @media (max-width: 640px) {
            font-size: 30px;
          }
        }

        .about-us-section-text-content {
          font-family: acumin-pro-wide, sans-serif;
          font-weight: 200;
          font-style: normal;
          font-size: 22px;

          @media (max-width: 640px) {
            font-size: 16px;
          }
        }
      }
    }
  }
}
