.product-page-container {
  .product-page-content-container {
    .product-page-hero-doors {
      background: url("/assets/images/doors/hero.jpg") no-repeat center center;
      background-size: cover;
    }

    .product-page-hero {
      height: 800px;

      @media (max-width: 600px) {
        height: 500px;
      }

      .product-page-hero-left {
        width: 50%;
        color: #ffffff;
        font-family: acumin-pro-wide, sans-serif;
        font-weight: 200;
        font-style: normal;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 116px;

        @media (max-width: 1200px) {
          padding: 60px;
          width: 60%;
        }

        @media (max-width: 1000px) {
          width: 70%;
        }

        @media (max-width: 640px) {
          width: 100%;
          padding: 30px;
        }

        .product-page-hero-left-text {
          font-size: 24px;
          max-width: 700px;

          @media (max-width: 600px) {
            font-size: 20px;
          }
        }
      }

      .product-page-hero-left-doors {
        background-color: rgba(106, 133, 39, 0.8);
      }
    }

    .product-page-split-section {
      display: flex;
      flex-direction: row;
      height: 600px;

      @media (max-width: 1000px) {
        flex-direction: column;
        height: 800px;
      }

      .product-page-split-section-image {
        background-size: cover;
        width: 50%;
        background-position: center center;

        @media (max-width: 1000px) {
          height: 400px;
          width: 100%;
        }
      }

      .doors-split-section-image-1 {
        background-image: url("/assets/images/doors/image_1.jpg");
      }

      .doors-split-section-image-2 {
        background-image: url("/assets/images/doors/image_2.jpg");
      }

      .doors-split-section-image-3 {
        background-image: url("/assets/images/doors/image_3.jpg");
      }

      .doors-split-section-image-4 {
        background-image: url("/assets/images/doors/image_4.jpg");
      }

      .product-page-split-section-text {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 100px;

        @media (max-width: 1000px) {
          height: 400px;
          width: 100%;
        }

        @media (max-width: 640px) {
          padding: 30px;
        }

        .product-page-split-section-text-title {
          text-transform: uppercase;
          font-family: acumin-pro-extra-condensed, sans-serif;
          font-weight: 500;
          font-style: normal;
          font-size: 34px;

          @media (max-width: 640px) {
            font-size: 24px;
          }
        }

        .product-page-split-section-text-content {
          margin-top: 24px;
          font-family: acumin-pro-wide, sans-serif;
          font-weight: 200;
          font-style: normal;
          font-size: 20px;

          @media (max-width: 640px) {
            font-size: 16px;
          }
        }
      }
    }

    .product-page-split-section-column-reverse {
      @media (max-width: 1000px) {
        flex-direction: column-reverse;
      }
    }

    .product-page-bottom-section-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 60px);
      min-height: calc(100vh - 60px);

      @media (max-width: 950px) {
        flex-direction: column;
      }

      .product-page-bottom-section-text {
        height: 100%;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 80px;

        @media (max-width: 950px) {
          height: 50%;
          width: 100%;
        }

        @media (max-width: 640px) {
          padding: 50px 30px;
        }

        .product-page-bottom-section-text-title {
          text-transform: uppercase;
          font-family: acumin-pro-extra-condensed, sans-serif;
          font-weight: 500;
          color: #ffffff;
          line-height: 60px;
          font-size: 48px;

          @media (max-width: 1300px) {
            line-height: 44px;
            font-size: 40px;
          }

          @media (max-width: 640px) {
            line-height: 30px;
            font-size: 30px;
          }
        }

        .product-page-bottom-section-text-content {
          margin-top: 48px;
          font-family: acumin-pro-wide, sans-serif;
          font-weight: 200;
          font-style: normal;
          color: #ffffff;
          font-size: 22px;

          @media (max-width: 1300px) {
            font-size: 18px;
          }

          @media (max-width: 640px) {
            font-size: 16px;
          }
        }
      }

      .doors-bottom-section-image-bifold {
        background: url("/assets/images/doors/bifold.png") no-repeat center
          center;
      }

      .doors-bottom-section-image-multislide {
        background: url("/assets/images/doors/multislide.png") no-repeat center
          center;
      }

      .product-page-bottom-section-image {
        background-size: contain;
        background-origin: content-box;
        height: 100%;
        width: 50%;
        padding: 50px;

        @media (max-width: 950px) {
          padding: 10px;
          width: 100%;
        }

        div {
          @media (max-width: 950px) {
            width: 100%;
          }

          img {
            width: 100%;
          }
        }
      }
    }

    .doors-bottom-section-container-bifold {
      background-color: #898b84;
    }

    .doors-bottom-section-container-multislide {
      background-color: #93a763;
    }
  }
}
