* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.App {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 1480px;
}

#root {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

a {
  text-decoration-line: none;
}
