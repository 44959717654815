.product-page-container {
  .product-page-hero-glass {
    background: url("/assets/images/glass/glass_hero.jpg") no-repeat center
      center;
    height: 800px;
    background-size: cover;

    .product-page-hero-left-glass {
      background-color: rgba(49, 130, 166, 0.8);
    }
  }

  .product-page-content-container {
    .glass-split-section-image-1 {
      background: url("/assets/images/glass/image_1.jpg");
    }

    .glass-split-section-image-2 {
      background: url("/assets/images/glass/image_2.jpg");
    }

    .glass-split-section-image-3 {
      background: url("/assets/images/glass/image_3.jpg");
    }

    .glass-split-section-image-4 {
      background: url("/assets/images/glass/image_4.jpg");
    }

    .doors-bottom-section-image-bifold {
      background: url("/assets/images/doors/bifold.png") no-repeat center center;
    }

    .doors-bottom-section-image-multislide {
      background: url("/assets/images/doors/multislide.png") no-repeat center
        center;
    }
  }

  .doors-bottom-section-container-bifold {
    background-color: #898b84;
  }

  .doors-bottom-section-container-multislide {
    background-color: #93a763;
  }
}
