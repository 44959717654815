.home-navigation {
  position: sticky;
  top: 0px;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  padding: 5px 30px;
  z-index: 2;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);

  @media (max-width: 800px) {
    height: 150px;
    padding: 0px 10px;
  }

  .home-navigation-container {
    width: 100%;
    max-width: 1450px;
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      height: 150px;
    }
  }

  .home-navigation-logo {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    max-width: 540px;

    @media (max-width: 800px) {
      margin-top: 20px;
    }

    .home-navigation-logo-image {
      img {
        height: 45px;
      }
    }

    .home-navigation-logo-glass-doors {
      margin-bottom: 7px;
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;

      img {
        height: 25px;
      }

      .home-navigation-logo-glass-doors-plus {
        margin-top: -8px;
        padding: 0 5px;
        height: 15px;
        img {
          height: 15px;
        }
      }
    }
  }

  .home-navigation-links {
    display: flex;
    flex-direction: row;
    padding-left: 50px;

    @media (max-width: 800px) {
      margin-top: 20px;
      padding-left: 0px;
    }

    img {
      height: 21px;
    }

    a {
      &:first-of-type {
        .home-navigation-link {
          margin-left: 0px;
        }
      }
    }

    .home-navigation-link {
      font-family: acumin-pro-extra-condensed, sans-serif;
      font-weight: 500;
      font-style: normal;
      line-height: 30px;
      font-size: 30px;
      margin-left: 30px;
      text-transform: uppercase;
      border-bottom: 3px solid transparent;
      color: #222222;

      &:hover {
        border-bottom: 3px solid green;
      }

      @media (max-width: 500px) {
        font-size: 26px;
        line-height: 26px;
        margin-left: 20px;
      }
    }
  }
}
