.footer-container {
  width: 100%;
  height: 650px;
  background-color: #5c5e55;
  padding: 120px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 640px) {
    padding: 40px;
  }

  .footer-availability {
    font-size: 24px;
    font-family: acumin-pro-wide, sans-serif;
    font-weight: 200;
  }

  .footer-availability-location {
    font-size: 36px;
    font-family: acumin-pro-extra-condensed, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
  }

  .footer-logo {
    margin-top: 125px;
  }

  .footer-address {
    margin-top: 10px;
    font-family: acumin-pro-wide, sans-serif;
    font-weight: 200;
    font-size: 24px;
  }
}
